import './bootstrap.js';
import {initCustom} from './custom.js';
import {initCalendar} from './calendar.js';
import {initDatepicker} from './datepicker.js';
import {initAnfrage} from './anfrage.js';

//alles was man inline in html verwenden will, kommt hier hin
window.jQuery = window.$ = $;

function onReady() {
	initCustom();
	initCalendar();
	initDatepicker();
	initAnfrage();
}

window.openLink = function(e) {
	e = e || window.event;
	
	var target = e.target || e.srcElement;
	var href = $(target).attr('href');

	if( href && !!window.opener ) {
		e.stopPropagation();
		
		window.opener.location = href;
		window.close();
	}
}

$(document).ready(function() {
	onReady();
});
