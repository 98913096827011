export function initAnfrage() {
	
	$('#anfrage-tab').find('.nav-link').on('click', function(e) {
		e.preventDefault();
		
		//zustand der tabs ändern
		$('#anfrage-tab').find('.nav-link').removeClass('active');
		$(this).addClass('active');
		
		//formular "filtern" und zustand des hidden fields ändern
		if( $(this).data('terminart') == 'periodisch' ) {
			$('#anfrage-form').removeClass('einzeltermin').addClass('periodisch')
				.find('[name=terminart]').val('periodisch');
		} else {
			$('#anfrage-form').removeClass('periodisch').addClass('einzeltermin')
				.find('[name=terminart]').val('einzeltermin');
		}
	});
	
	$('#anfrage-form').find('.form-control, .custom-select, .form-check-input').on('change', function() {
		if( $(this).is('.is-invalid') ) {
			$(this).removeClass('is-invalid');
		}
	});
}