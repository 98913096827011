import 'slick-carousel';

export function initCalendar() {
	
	$('#sporthallen-kalender').slick({
		dots: false,
		infinite: false,
		useTransform: true,
		speed: 500,
		appendArrows: $('#kalender-buttons'),
		prevArrow: '<div class="prevArrow"><svg><use href="#chevron"></use></svg></div>',
		nextArrow: '<div class="nextArrow"><svg><use href="#chevron"></use></svg></div>',
		cssEase: 'linear',
		slidesToShow: 7,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 620,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 530,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 440,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});
	
	$('#sporthallen-kalender .buchung, #sporthallen-kalender .calItem').tooltip({
		container: 'body',
		html: true
	});
}